<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 d-flex justify-content-between">
        <div class="font-title">質問管理</div>
        <div class="w-25 py-3">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
          <button
            v-on:click="createSurvey()"
            class="float-right button-create-user"
          >
            フォームの編集
          </button>
        </div>
      </div>
      <div class="row p-3 d-flex">
        <div class="row-input p-3 w-50">
          <label class="col-sm-5 font-weight-bold pl-0">タイトル</label>
          <input class="form-input col-sm-12" v-model="survey.title" />
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListSurveys(1)"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listSurvey.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{
              (listSurvey.current_page - 1) * listSurvey.per_page + index + 1
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div>
              <CButton
                class="mx-1"
                style="width: 67px"
                color="success"
                square
                v-on:click="deleteInquiries(item.id)"
                variant="outline"
                size="sm"
              >
                結果DL
              </CButton>
            </div>
            <div class="w-5">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'edit survey'
                    : 'edit survey domain',
                  params: {
                    shopId: shop_id,
                    id: item.id,
                  },
                  query: {
                    NO:
                      (listSurvey.current_page - 1) * listSurvey.per_page +
                      index +
                      1,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1"
                  style="width: 67px"
                  color="primary"
                  square
                  variant="outline"
                  size="sm"
                >
                  確認
                </CButton>
              </router-link>
            </div>
            <div>
              <CButton
                class="mx-1"
                style="width: 67px"
                color="danger"
                square
                v-on:click="deleteSurveys(item.id)"
                variant="outline"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSurvey.total"
      >
        <p class="font-weight-bold">
          {{ listSurvey.total }}件中{{ listSurvey.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSurvey.last_page"
      >
        <CPagination
          v-if="listSurvey.total"
          :activePage.sync="page"
          :pages="listSurvey.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-survey" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="
              deleteSurvey({ shopId: shop_id, id: survey.id })
            "
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-survey')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
// import { Constants } from "../../utils/constants";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListNew",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      survey: {
        id: "",
        title: "",
      },
      fields: tableFields.SURVEY,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListSurveys(1);
  },
  computed: {
    ...mapGetters(["listSurvey", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-survey");
        this.getListSurveys(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSurvey() {
      this.page = this.listSurvey.current_page;
    },
    page() {
      this.getListSurveys(this.page);
    },
  },
  methods: {
    ...mapActions({ getListSurvey: "getListSurvey" }),
    ...mapActions({ deleteSurvey: "deleteSurvey" }),
    createSurvey() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "create survey",
          params: { shopId: this.shop_id, page: this.page },
          query: {
            total: this.listSurvey.total ? this.listSurvey.total + 1 : 1,
          },
        });
      } else {
        this.$router.push({
          name: "create survey domain",
          params: { page: this.page },
          query: {
            total: this.listSurvey.total ? this.listSurvey.total + 1 : 1,
          },
        });
      }
    },
    getListSurveys(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          survey_title: this.survey.title,
          limit: this.limit,
        },
      };
      this.getListSurvey(formData);
    },
    changeLimitPage() {
      this.getListSurveys(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      this.survey.title = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          survey_title: this.survey.title,
          limit: this.limit,
        },
      };
      this.getListSurvey(formData);
    },
    deleteSurveys(id) {
      this.survey.id = id;
      this.$bvModal.show("delete-modal-survey");
    },
  },
};
</script>
